/* eslint-disable max-len */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OptionsDashboard,
  PopularProducts,
  PriceOfferModel,
  UnitTypeDetailsModel,
} from '@atlas-workspace/shared/models';
import { environment } from '@environment-client';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardOptionsService {
  constructor(private http: HttpClient) { }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Client-Options-Dashboard/paths/~1api~1v1~1client~1projects~1%7Bproject_id%7D~1units~1%7Bunit_id%7D~1options/get
   */
  public getUnitOptions(projectId: number, unitId: number): Observable<OptionsDashboard> {
    const params = new HttpParams().set('only_image_versions', 'w260');
    return this.http.get(environment.apiBaseUrl + `api/v1/client/projects/${projectId}/units/${unitId}/options`, { params }).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(OptionsDashboard, data)),
    );
  }

  public getPopularProducts(projectId: number, unitId: number): Observable<PopularProducts[]> {
    return this.http
      .get<{
        data: { products: [] };
      }>(environment.apiBaseUrl + `api/v1/client/projects/${projectId}/units/${unitId}/options/popular_products`)
      .pipe(
        map((res: any) => res.data.products),
        map((data: []) => plainToClass(PopularProducts, data)),
      );
  }

  public addProductToWishList(projectId: number, unitId: number, productId: number): Observable<never> {
    const fd = new FormData();
    fd.append('wishlist[wishlist_items_attributes][][layout_product_type_item_id]', String(productId));
    return this.http
      .put(environment.apiBaseUrl + `api/v1/client/projects/${projectId}/units/${unitId}/wishlist`, fd)
      .pipe(map((res: any) => <never>res.data));
  }

  public getUnitType(projectId: number, unitTypeId: number): Observable<UnitTypeDetailsModel> {
    return this.http
      .get(environment.apiBaseUrl + `api/v1/client/projects/${projectId}/layout_types/${unitTypeId}`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitTypeDetailsModel, data)),
      );
  }

  public getPriceOffer(projectId: number, unitTypeId: number, offerId: number): Observable<PriceOfferModel> {
    return this.http
      .get(environment.apiBaseUrl + `api/v1/projects/${projectId}/layout_types/${unitTypeId}/price_offers/${offerId}`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(PriceOfferModel, data)),
      );
  }

  /**
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Client-Price-Offer/paths/~1api~1v1~1projects~1{project_id}~1layout_types~1{layout_type_id}~1price_offers~1{id}~1cancel/post
   */
  public cancelPriceOffer(projectId: number, unitTypeId: number, offerId: number): Observable<any> {
    return this.http
      .post(
        environment.apiBaseUrl +
        `api/v1/projects/${projectId}/layout_types/${unitTypeId}/price_offers/${offerId}/cancel`,
        null,
      )
  }

  /**
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Client-Products/paths/~1api~1v1~1projects~1{project_id}~1layout_types~1{layout_type_id}~1layout_option_categories~1{layout_option_category_id}~1layout_product_types~1{layout_product_type_id}~1products~1{id}~1request_price/post
   */
  requestPriceForItem(projectId: number, unitTypeId: number, categoryId: number, typeId: number, productId: number, unitId: number): Observable<any> {
    const params = new HttpParams().set('only_image_versions', 'w260').append('unit_id', unitId.toString());
    return this.http
      .post(
        environment.apiBaseUrl +
        `api/v1/projects/${projectId}/layout_types/${unitTypeId}/layout_option_categories/${categoryId}/layout_product_types/${typeId}/products/${productId}/request_price`, null,
        { params },
      )
  }


  /**
   * @see https://api.journeyapp.uat.scrij.com/api-docs#tag/Client-Price-Offer/paths/~1api~1v1~1projects~1{project_id}~1layout_types~1{layout_type_id}~1price_offers~1{id}~1accept/post
   */
  public acceptOffer(projectId: number, unitTypeId: number, offerId: number): Observable<boolean> {
    return this.http
      .post(
        environment.apiBaseUrl +
        `api/v1/projects/${projectId}/layout_types/${unitTypeId}/price_offers/${offerId}/accept`,
        null,
      )
      .pipe(map(() => true));
  }

  public declineOffer(projectId: number, unitTypeId: number, offerId: number): Observable<boolean> {
    return this.http
      .post(
        environment.apiBaseUrl +
        `api/v1/projects/${projectId}/layout_types/${unitTypeId}/price_offers/${offerId}/decline`,
        null,
      )
      .pipe(map(() => false));
  }
}
