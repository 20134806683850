import { Injectable } from '@angular/core';
import { OptionsDashboard } from '@atlas-workspace/shared/models';
import { Action, Selector,State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DashboardOptionsService } from '../../modules/options/services/dashboard-options.service';

import { OptionsDashboardStateModel } from './options-dashboard-state.model';
import { ClearOptionsDashboard, SetOptionsDashboard } from './options-dashboard.actions';

const defaults: OptionsDashboardStateModel = {
  optionsDashboardState: null,
};

@State<OptionsDashboardStateModel>({
  name: 'optionsDashboardState',
  defaults,
})
@Injectable()
export class OptionsDashboardState {
  constructor(private optionsService: DashboardOptionsService) {}

  @Selector()
  static getOptionsDashboard(state: OptionsDashboardStateModel): OptionsDashboard | null {
    return state.optionsDashboardState;
  }

  @Action(SetOptionsDashboard)
  setOptionsDashboard(
    ctx: StateContext<OptionsDashboardStateModel>,
    action: SetOptionsDashboard
  ): void {
    ctx.patchState({ optionsDashboardState: action.payload });
  }

  @Action(ClearOptionsDashboard)
  clearOptionsDashboard(ctx: StateContext<OptionsDashboardStateModel>): void {
    ctx.patchState({ optionsDashboardState: null });
  }

  fetchAndSetOptionsDashboard(
    ctx: StateContext<OptionsDashboardStateModel>,
    projectId: number,
    unitId: number
  ): Observable<OptionsDashboard> {
    return this.optionsService.getUnitOptions(projectId, unitId).pipe(
      tap((result: OptionsDashboard) => {
        ctx.dispatch(new SetOptionsDashboard(result));
      })
    );
  }
}