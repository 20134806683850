import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmSignicatComponent } from './confirm-signicat.component';

@NgModule({
  declarations: [ConfirmSignicatComponent],
  exports: [ConfirmSignicatComponent],
  imports: [CommonModule, TranslateModule],
})
export class ConfirmSignicatModule {}
