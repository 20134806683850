import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective, OnboardingSelectorDirective } from '@atlas-workspace/shared/directives';
import { CustomIconComponent, GroupedDropDownComponent, HeaderIconComponent } from '@atlas-workspace/shared/ui';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';

import { OptionsDashboardState } from '../../../../store/options/options-dashboard.state';
import { LeftMenuComponent } from './left-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    OnboardingSelectorDirective,
    CustomIconComponent,
    TranslateModule,
    ClickOutsideDirective,
    NgbTooltipModule,
    NgbDropdownModule,
    HeaderIconComponent,
    GroupedDropDownComponent,
    NgxsModule.forFeature([OptionsDashboardState]),
  ],
  declarations: [LeftMenuComponent],
  exports: [LeftMenuComponent],
})
export class LeftMenuModule {}
