import { Pipe, PipeTransform } from '@angular/core';
import { ERuleKind, RuleDependableProduct } from '@atlas-workspace/shared/models';

@Pipe({
  name: 'filterSizingKinds',
  standalone: true,
})
export class FilterSizingRuleKindsPipe implements PipeTransform {
  transform(
    dependableProducts: RuleDependableProduct[]
  ): RuleDependableProduct[] {
    if (!Array.isArray(dependableProducts)) {
      return [];
    }

    return dependableProducts.filter((item) => item.ruleKind !== ERuleKind.Decreasing && item.ruleKind !== ERuleKind.Increasing);
  }
}