import { OptionsDashboard } from "@atlas-workspace/shared/models";

export class SetOptionsDashboard {
    static readonly type = '[OptionsDashboard] Set';
    constructor(public payload: OptionsDashboard) {}
  }
  
  export class ClearOptionsDashboard {
    static readonly type = '[OptionsDashboard] Clear';
  }

  export class FetchAndSetOptionsDashboard {
    static readonly type = '[OptionsDashboard] Fetch and Set';
    constructor(public projectId: number, public unitId: number) {}
  }